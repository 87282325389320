import { Route, Routes, useLocation } from '@remix-run/react';
import { useEffect } from 'react';

import { PortalLink } from '../../components/PortalContext';
import { useUserRedirect } from '../../hooks/useRedirection';
import { MinigameCenter, Wrapper } from '../Minigame';
import { MyGameList, SearchGameList, TagGameList } from '../Minigame/List';
import { HostView } from './HostView';

// eslint-disable-next-line import/no-default-export
export default function MinigameRoute(): JSX.Element {
  const location = useLocation();
  const redirect = useUserRedirect();

  useEffect(() => {
    redirect([
      {
        kind: 'admin',
        target: location.pathname.replace(/^\/host/, '/admin'),
      },
    ]);
  }, [location, redirect]);
  return (
    <HostView
      search={{
        targetPathname: 'search',
        placeholder: 'Search Minigames',
      }}
      className='bg-game-library bg-w-full bg-no-repeat bg-local'
    >
      <Wrapper routePrefix={PortalLink.HostHome} mode='host'>
        <Routes>
          <Route index element={<MinigameCenter />} />
          <Route path={`my`} element={<MyGameList />} />
          <Route path={`tags/:slug`} element={<TagGameList />} />
          <Route path={`search`} element={<SearchGameList />} />
        </Routes>
      </Wrapper>
    </HostView>
  );
}
